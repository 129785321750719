import { createStore } from 'vuex'
import axios from '../config/network-wrapper'

const store = createStore({
  state: {
    isMenuOpen: false
  },
  mutations: {
    toggleMenu (state) {
      state.isMenuOpen = !state.isMenuOpen
    },
  },
  actions: {
    setMenuOpenState (context) {
      context.commit('toggleMenu')
    },
    async submit (context, payload) {
      return await axios.post('sendgrid/submit_contact/ng_accounting', payload)
    }
  },
  getters: {
    openMenu (state) {
      return state.isMenuOpen
    },
  },
  modules: {
  }
})

export default store